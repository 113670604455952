<script setup>

// eslint-disable-next-line no-unused-vars
import { ref, onMounted, computed, onUpdated, Ref, watch } from "vue";

import { COLORS } from "../lib/colors"

import BowlList from "../components/BowlList.vue";
import BowlContent from "../components/BowlContent.vue";
import { createBowlObj, createBreadCalcObj, createNodeObj, createDefaultBread } from "@/lib/breadcalc/functions";



/**
 * @type {Ref<BreadCalcObj | null>}
 */
const breadCalcState = ref(null)


/**
 *
 * @param {(state: BreadCalcObj) => BreadCalcObj | void} updater
 */
function updateState(updater) {
  const newState = updater(breadCalcState.value)
  breadCalcState.value = newState ?? {...breadCalcState.value}
}



function addBowl() {
  updateState((state) => {
    const index = state.bowls.length
    const name = (index + 1).toString()
    const bowl = createBowlObj(name)
    bowl.root = createNodeObj("bread")

    state.bowls.push(bowl)
    state.currentBowlIndex = index
  })
}

function changeBowl(bowlIndex) {
  updateState((state) => {
    state.currentBowlIndex = bowlIndex
  })
}

function emptyBowl(bowlIndex) {
  updateState((state) => {
    const bowl = state.bowls[bowlIndex]

    if (bowl) {
      bowl.root = createNodeObj("bread")
    }

    /*

    let lastBowl = bowls[bowls.length-1]

    while (lastBowl && lastBowl.content.length == 0) {
      bowls.pop()
      lastBowl = bowls[bowls.length-1]
    }
    */
  })
}



function fixBowl(bowl) {
  if (!bowl || !bowl.content) {
    return
  }

  for (const ingredient of bowl.content) {
    if (!ingredient.color) {
      ingredient.color = COLORS[bowl.nextColorIndex];
      bowl.nextColorIndex += 1;
    }
  }
}

function fixBowls(bowls) {
  for (const bowl of bowls) {
    fixBowl(bowl)
  }
}


function fixEverything() {
  /*
  if (bowls.length <= 0) {
    bowls.push(new Bowl(
      "1",
      createDefaultBread(),
    ))
  }

  if (bowls.length > 0 && !(0 <= currentBowlIndex.value && currentBowlIndex.value < bowls.length)) {
    currentBowlIndex.value = 0
  }

  */

  fixBowls([])
}


onMounted(() => {
  fixEverything()

  if (!breadCalcState.value) {
    const stateStr = localStorage.getItem("breadcalc-state")

    /**
     * @type {BreadCalcObj}
     */
    const state = stateStr ? JSON.parse(stateStr) : createBreadCalcObj()

    if (state) {
      breadCalcState.value = state
    }

  }
})

onUpdated(() => {
  fixEverything()
})

watch(breadCalcState, (value) => {
  localStorage.setItem("breadcalc-state", JSON.stringify(value))
})


const selectedBowl = computed(() => {
  const state = breadCalcState.value

  if (!state) {
    return null
  }

  const index = state.currentBowlIndex

  if (!(0 <= index && index < state.bowls.length)) {
    return null
  }

  const bowl = state.bowls[index]

  if (!bowl) {
    return null
  }

  return bowl
})

const otherBowl = computed(() => {
  const state = breadCalcState.value

  if (!state) {
    return null
  }

  if (state.bowls.length != 2) {
    return null
  }

  const index = state.currentBowlIndex === 0 ? 1 : 0

  const bowl = state.bowls[index]

  if (!bowl) {
    return null
  }

  return bowl
})

/*
function copyToBowl(originBowlIndex, destBowlIndex, move=false) {

}
*/
</script>

<template>
  <div class="container" v-if="breadCalcState">
    <bowl-list
      :bowls="breadCalcState.bowls"
      :currentBowlIndex="breadCalcState.currentBowlIndex"
      @changeBowl="changeBowl"
      @addBowl="addBowl"
    ></bowl-list>

    <bowl-content v-if="selectedBowl" :bowl="selectedBowl" @update="fixEverything()"></bowl-content>

    <button @click="emptyBowl(currentBowlIndex)">Empty bowl</button>

    <template v-if="breadCalcState.bowls.length == 2">
      <button>Move to bowl {{ otherBowl.name }}</button>
      <button>Copy to bowl {{ otherBowl.name }}</button>
    </template>

    <template v-if="breadCalcState.bowls.length > 2">
      <button>Move to bowl ...</button>
      <button>Copy to bowl ...</button>
    </template>
  </div>
</template>

<style scoped>

</style>
