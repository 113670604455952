import { isString } from "@vue/shared"
import { capitalize } from "../helpers"
import { INGREDIENT_BAKERS_100PCT } from "./const"


/**
 * A node in a tree of ingredients
 * A node maintains a pointer to its parent
 * and to a Tree object that contains useful information shared
 * among the entire tree.
 */
export class Node {
    /**
     *
     * @param {string} name
     */
    constructor(name) {
        /**
         * @type {string}
         */
        this.name = name

        /**
         * @type {number}
         */
        this._weight = -1

        /**
         * @type {Node[]}
         */
        this.children = []

        /**
         * @type {?string}
         */
        // If we start having a lot of "off-topic" metadata, we should find another solution.
        this.color = null

        /**
         * @type {?Node}
         */
        this.parent = null

        /**
         * @type {Tree}
         */
        this.tree = new Tree()  // Every node starts out as its own tree until it gets merge into a bigger tree
        this.tree.updateFromRoot(this)
    }

    get prettyName() {
        return capitalize(this.name)
    }

    /**
     * @type {Node}
     */
    get root() {
        let ing = this
        while (ing.parent) {
            ing = ing.parent
        }
        return ing
    }

    /**
     * @type {number}
     */
    get weight() {
        if (this._weight < 0) {
            return this.computeWeight()
        }
        return this._weight
    }


    set weight(value) {
        this.setWeight(value)
    }

    computeWeight() {
        if (this.children.length === 0) {
            return this._weight
        }

        return this.children.map((ing) => ing.computeWeight()).reduce((a, b) => {
            return a + b
        }, 0)
    }


    get localPercent() {
        if (!this.parent) {
            return 1
        }

        let sum = 0

        for (const sibling of this.parent.children) {
            sum += sibling.weight
        }

        return this.weight / sum
    }

    get percent() {
        return this.weight / this.tree.relativeTo.weight
    }

    set percent(value) {
        this.setPercent(value)
    }

    get length() {
        return this.children.length
    }

    [Symbol.iterator]() {
        return this.values()
    }

    values() {
        return this.children.values()
    }

    add(name, weight) {

        if (name instanceof Node) {
            this.addChild(name)
            if (weight !== undefined) {
                name.setWeight(weight)
            }
            return
        }

        const node = new Node(name)
        this.addChild(node)
        if (weight !== undefined) {
            node.setWeight(weight)
        }
        return this
    }

    addMultiple(ingredients) {
        for (const ing of ingredients) {
            if (Array.isArray(ing)) {
                this.add(ing[0], ing[1])
            } else if (isString(ing)) {
                this.add(ing)
            }
        }
        return this
    }

    /**
     *
     * @param {Node} node
     * @returns
     */
    addChild(node) {
        if (!(node instanceof Node)) {
            throw new Error(`${node} must be of type Node, got ${typeof node}`)
        }

        if (node.name === this.name) {
            throw new Error(`Cannot add ${node.name} under itself`)
        }

        // Maintain tree pointer
        //this.tree.mergeIn(node.tree)
        //node.tree = null

        for (const existingNode of this.children) {
            if (node.name === existingNode.name) {
                // We already have a node of this name. Merge them together
                existingNode.mergeIn(node)
                return
            }
        }

        // We have reached here without returning, thus the node does not already exist

        // Add it
        node.parent = this
        this.children.push(node)

        this.tree.updateFromRoot(node)

        return this  // Chaining
    }

    deleteChild(node) {
        if (isString(node)) {
            node = this.get(node)
        }

        this.children = this.children.filter(n => n !== node)

        return this
    }

    get(name) {
        let path = name
        if (isString(path)) {
            path = path.split(".")
        }

        const childName = path.shift()

        const child = this.children.find((node) => {
            return node.name === childName
        })

        if (!child) {
            return undefined
        }

        return path.length === 0 ? child : child.get(path)

    }

    // eslint-disable-next-line no-unused-vars
    setWeight(value, keepParentConstant = false) {
        const prevWeight = this.weight
        this._weight = value

        const scale = this._weight / prevWeight

        for (const child of this.children) {
            child.weight *= scale
        }

        return this  // To allow for chaining
    }

    // eslint-disable-next-line no-unused-vars
    setPercent(value, keepParentConstant = false) {
        if (!(0 <= value && value <= 1)) {
            throw new Error(`ValueError: Percentage must between 0 and 1. Got ${value}`)
        }

        this.weight = value * this.tree.relativeTo.weight

        return this  // To allow for chaining
    }

    /**
     *
     * @param {(node: Node) => "stop" | "skip-children" | undefined} callback
     */
    traverse(callback) {
        /**
         *
         * @param {Node} node
         * @returns
         */
        function visit(node) {
            const action = callback(node)

            if (action === "stop") {
                throw new StopTraversal()
            } else if (action === "skip-children") {
                return
            }

            for (const child of node.children) {
                visit(child)
            }
        }

        try {
            visit(this)
        } catch (e) {
            if (!(e instanceof StopTraversal)) {
                throw e
            }
        }
    }

    subtreeSize() {
        return 1 + this.children.map(child => child.subtreeSize())
    }

    /**
     *
     * @param {Node} node
     */
    mergeIn(node) {
        if (!(node instanceof Node)) {
            throw new Error(`${node} must be of type Node, got ${typeof node}`)
        }

        this._weight += node._weight
        node._weight = 0

        for (const child of node) {
            this.addChild(child)
        }

        // After node has been merged in, it should be considered deleted
    }
}



/**
 * Every node belongs to a tree and has a reference to the same instance of this class.
 * It contains various useful information about the tree.
 */
class Tree {
    constructor(relativeToName) {
        this.relativeToName = relativeToName

        if (!this.relativeToName) {
            this.relativeToName = INGREDIENT_BAKERS_100PCT
        }

        this.sameNameSets = new Map()  // Node name -> SameNameSet
    }

    get relativeTo() {
        const sameNameSet = this.sameNameSets.get(this.relativeToName)
        if (!sameNameSet) {
            throw new Error("Could not find relativeTo")
        }
        return sameNameSet
    }

    updateFromRoot(node) {
        if (!(node instanceof Node)) {
            throw new Error(`${node} must be of type Node, got ${typeof node}`)
        }

        node.traverse((n) => {
            const sameNameSet = this.sameNameSets.get(n.name) ?? new SameNameSet()

            sameNameSet.add(n)

            this.sameNameSets.set(n.name, sameNameSet)

            n.tree = this
        })
    }

    mergeIn(otherTree) {
        if (!(otherTree instanceof Tree)) {
            throw new Error(`${otherTree} must be of type Tree, got ${typeof otherTree}`)
        }

        for (const [name, set] of otherTree.sameNameSets.entries()) {
            const existingSet = this.sameNameSets.get(name) ?? new SameNameSet()

            for (const node of set) {
                existingSet.add(node)
            }

            this.sameNameSets.set(name, existingSet)
        }

        // otherTree should not be considered deleted
    }

    get(name) {
        return this.sameNameSets.get(name)
    }
}


/**
 * Invariant: All items are of type Node and have the same name.
 */
class SameNameSet extends Set {

    get representativeNode() {
        for (const node of this) {
            return node
        }

        return undefined
    }

    get name() {
        return this._name ? this.name : this.representativeNode?.name
    }

    set name(value) {
        this._name = value
    }

    get prettyName() {
        return this._prettyName ? this._prettyName : this.representativeNode?.prettyName
    }

    set prettyName(value) {
        this._prettyName = value
    }

    get tree() {
        return this.representativeNode?.tree
    }

    get weight() {
        let w = 0
        for (const node of this) {
            w += node.weight
        }
        return w
    }

    // eslint-disable-next-line no-unused-vars
    setWeight(value) {

    }

    get percent() {
        return this.weight / this.tree.relativeTo.weight
    }

    copy() {
        return new SameNameSet(this)
    }
}


class StopTraversal extends Error { }


export function createBread(ingredients) {
    const node = new Node("bread")

    if (ingredients) {
        node.addMultiple(ingredients)
    }

    return node
}


export function createDefaultBread() {
    const bread = createBread([
        ["flour", 500],
        ["water", 300],
        ["yeast", 5],
        ["salt", 10],
    ])

    /*
    const pref = createBread([
        ["flour", 100],
        ["water", 100],
    ])

    pref.name = "preferment"

    bread.addChild(pref)
    */

    return bread
}
