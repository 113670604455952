<script setup>
import { computed, defineProps } from 'vue';

const props = defineProps(["size"])

const cssVars = computed(() => {
    return {
        "--size": props.size ?? 1,
        "--unit": "1em",
        "--color": props.color ?? "#000",
        "--font-color": props.color ?? "#fff",
    }
})
</script>

<template>
    <div class="container clickable no-select" :style="cssVars">
        <div class="spout" :style="cssVars"></div>
        <div class="body" :style="cssVars">
            <div class="content">
                <slot></slot>
            </div>
        </div>
        <div class="handle" :style="cssVars"></div>
    </div>
</template>

<style scoped>
.container {
    box-sizing: border-box;
    position: relative;
    width: calc(var(--size) * var(--unit));
    height: calc(var(--size) * var(--unit));
}

.container.clickable {
    cursor: pointer;
}

.container.no-select {
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.spout {
    position: absolute;
    top: 0;
    left: calc(-0.2 * var(--size) * var(--unit));
    width: 0;
    height: 0;
    border-left: calc(0.2 * var(--size) * var(--unit)) solid transparent;
    border-top: calc(0.12 * var(--size) * var(--unit)) solid var(--color);
    border-right: calc(0.2 * var(--size) * var(--unit)) solid transparent;
}

.body {
    box-sizing: border-box;
    position: absolute;
    width: calc(var(--size) * var(--unit));
    height: calc(var(--size) * var(--unit));
    background-color: var(--color);
    border-radius: 0 0 30% 30%;

}
.body .content {
    box-sizing: border-box;
    padding: calc(0.05 * var(--size) * var(--unit));
    font-size: calc(0.75 * var(--size) * var(--unit));
    color: var(--font-color);
}

.handle {
    position: absolute;
    top: 0;
    right: calc(-0.2 * var(--size) * var(--unit));
    width: calc(0.2 * var(--size) * var(--unit));
    height: calc(0.2 * var(--size) * var(--unit));
    background-color: var(--color);
    border-radius: 0 30% 50% 0;
}
</style>