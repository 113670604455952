<template>
  <div class="tab-container">
    <slot></slot>
  </div>
</template>

<script setup>
</script>

<style scoped>
.tab-container {
  display: flex;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  padding: 10px 0;
  scrollbar-width: none;
  /* Hide scrollbar for Firefox */
}

.tab-container::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar for Webkit (Chrome, Safari, etc.) */
}
</style>
