import script from "./IngredientControls.vue?vue&type=script&setup=true&lang=js"
export * from "./IngredientControls.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QBtnGroup from 'quasar/src/components/btn-group/QBtnGroup.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSlider from 'quasar/src/components/slider/QSlider.js';
import QInput from 'quasar/src/components/input/QInput.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtnGroup,QBtn,QSlider,QInput});
