export function round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

/**
 *
 * @param {string} word
 * @returns {string}
 */
export function capitalize(word) {
    return word.charAt(0).toUpperCase() + word.slice(1)
}

export function renamed(entity, newName) {
    return new Proxy(entity, {
        get(target, prop, receiver) {
            if (prop === "name" || prop === "prettyName") {
                return newName
            }

            let x = Reflect.get(target, prop, receiver);
            if (typeof x === "function") {
                x = x.bind(entity)
            }
            return x
        }
    })
}


/**
 *     const newSet = set.copy()
    newSet.name = newName
    newSet.prettyName = newName
    return newSet
}
 */