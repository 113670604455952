<script setup>
import { defineProps, defineEmits, computed } from "vue";

import { round, renamed } from "../lib/helpers";
import { nodeObjToNode } from "@/lib/breadcalc/functions";

const props = defineProps(["bread"]);

defineEmits(["select"]);



function nodePercent(node) {
  try {
    return round(node.percent * 100, 1)
  } catch (e) {
    return null
  }
}


const ingredients = computed(() => {
  if (!props.bread) {
    return []
  }

  const node = nodeObjToNode(props.bread)

  return [...node.children]
})


const overallIngredients = computed(() => {
  if (!props.bread) {
    return []
  }

  const output = []

  const node = nodeObjToNode(props.bread)
  const flourNode = node.tree.get("flour")

  if (flourNode) {
    output.push(renamed(flourNode, "Total flour"))
  }

  const waterNode = node.tree.get("water")

  if (waterNode) {
    output.push(renamed(waterNode, "Total water"))
  }

  output.push(renamed(node, "Total dough"))

  return output
})

const tableItems = computed(() => {
  return [...ingredients.value, ...overallIngredients.value]
})
</script>

<template>
  <div class="recipe">
    <div class="table">
      <div class="theader">
        <div class="table_header">Item</div>
        <div class="table_header">Weight</div>
        <div class="table_header">Percent</div>
      </div>

      <template v-for="item of tableItems" :key="item.name">
        <div class="table_row">
          <div class="table_small">
            <div class="table_cell">Item</div>
            <div class="table_cell">{{ item.prettyName }}</div>
          </div>
          <div class="table_small">
            <div class="table_cell">Weight</div>
            <div class="table_cell">{{ round(item.weight) }} g</div>
          </div>
          <div class="table_small">
            <div class="table_cell">Percent</div>
            <div class="table_cell" v-if="nodePercent(item) !== null">{{ nodePercent(item) }}%</div>
            <div class="table_cell" v-else>&nbsp;</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>





<style scoped>
.recipe {
  padding: 1em;
}

/* Table */

/* From: https://codepen.io/amwill04/pen/QNPpqx */

.table {
  display: table;
  text-align: center;
  width: 100%;
  border-collapse: separate;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}

.table_row {
  display: table-row;
}

.theader {
  display: table-row;
}

.table_header {
  display: table-cell;
  border-bottom: #ccc 1px solid;
  border-top: #ccc 1px solid;
  background: #bdbdbd;
  color: #e5e5e5;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 700;
}

.table_header:first-child {
  border-left: #ccc 1px solid;
  border-top-left-radius: 5px;
}

.table_header:last-child {
  border-right: #ccc 1px solid;
  border-top-right-radius: 5px;
}

.table_small {
  display: table-cell;
}

.table_row>.table_small>.table_cell:nth-child(odd) {
  display: none;
  background: #bdbdbd;
  color: #e5e5e5;
  padding-top: 10px;
  padding-bottom: 10px;
}

.table_row>.table_small>.table_cell {
  padding-top: 3px;
  padding-bottom: 3px;
  color: #5b5b5b;
  border-bottom: #ccc 1px solid;
}

.table_row>.table_small:first-child>.table_cell {
  border-left: #ccc 1px solid;
}

.table_row>.table_small:last-child>.table_cell {
  border-right: #ccc 1px solid;
}

.table_row:last-child>.table_small:last-child>.table_cell:last-child {
  border-bottom-right-radius: 5px;
}

.table_row:last-child>.table_small:first-child>.table_cell:last-child {
  border-bottom-left-radius: 5px;
}

.table_row:nth-child(2n + 3) {
  background: #e9e9e9;
}

@media screen and (max-width: 900px) {
  .table {
    width: 90%;
  }
}

@media screen and (max-width: 650px) {
  .table {
    display: block;
  }

  .table_row:nth-child(2n + 3) {
    background: none;
  }

  .theader {
    display: none;
  }

  .table_row>.table_small>.table_cell:nth-child(odd) {
    display: table-cell;
    width: 50%;
  }

  .table_cell {
    display: table-cell;
    width: 50%;
  }

  .table_row {
    display: table;
    width: 100%;
    border-collapse: separate;
    padding-bottom: 20px;
    margin: 5% auto 0;
    text-align: center;
  }

  .table_small {
    display: table-row;
  }

  .table_row>.table_small:first-child>.table_cell:last-child {
    border-left: none;
  }

  .table_row>.table_small>.table_cell:first-child {
    border-left: #ccc 1px solid;
  }

  .table_row>.table_small:first-child>.table_cell:first-child {
    border-top-left-radius: 5px;
    border-top: #ccc 1px solid;
  }

  .table_row>.table_small:first-child>.table_cell:last-child {
    border-top-right-radius: 5px;
    border-top: #ccc 1px solid;
  }

  .table_row>.table_small:last-child>.table_cell:first-child {
    border-right: none;
  }

  .table_row>.table_small>.table_cell:last-child {
    border-right: #ccc 1px solid;
  }

  .table_row>.table_small:last-child>.table_cell:first-child {
    border-bottom-left-radius: 5px;
  }

  .table_row>.table_small:last-child>.table_cell:last-child {
    border-bottom-right-radius: 5px;
  }
}</style>