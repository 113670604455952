<script setup>
import { defineProps, defineEmits } from "vue";

const props = defineProps(["ingredients", "highlightedIngredient", "currentIngredientIndex"]);

defineEmits(["select"]);

function itemStyle(item) {
  return {
    width: item.localPercent * 100 + "%",
    "background-color": item.color ?? "gray",
  };
}
</script>


<template>
  <div class="visualization">
    <div
      class="item"
      v-for="item of props.ingredients"
      :key="item.name"
      :style="itemStyle(item)"
      @click="$emit('select', item)"
    ></div>
    <div class="item filler" id="filler"></div>
  </div>
  <div v-if="false" class="legend">
    <template v-if="highlightedIngredient">
      <h3>{{ highlightedIngredient.name }}</h3>
    </template>
  </div>
</template>


<style scoped>
.visualization {
  display: flex;
  flex-direction: row;

  margin: 1em;
}

.visualization .item {
  height: 10em;
  box-sizing: border-box;
}

.visualization .item:hover {
  border: 0.5em solid rgba(255, 255, 255, 0.25);
}

.visualization .item.filler,
#filler {
  flex: 1 1 auto;
  background-image: linear-gradient(
    135deg,
    #d1d1d1 25%,
    #c9c9c9 25%,
    #c9c9c9 50%,
    #d1d1d1 50%,
    #d1d1d1 75%,
    #c9c9c9 75%,
    #c9c9c9 100%
  );
  background-size: 42.43px 42.43px;
}

#filler:hover {
  border: none;
}

/*
.visualization .item:nth-of-type(1) {
  background: #1f77b4;
}

.visualization .item:nth-of-type(2) {
  background: #ff7f0e;
}

.visualization .item:nth-of-type(3) {
  background: #2ca02c;
}

.visualization .item:nth-of-type(4) {
  background: #d62728;
}

.visualization .item:nth-of-type(5) {
  background: #9467bd;
}
*/

.legend {
  padding: 1em;
}
</style>