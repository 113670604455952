import { Node } from "./tree"
import { TreePath } from "./path"
import { Bowl } from "./bowl"


/**
 *
 * @param {string} name
 * @returns {NodeObj}
 */
export function createNodeObj(name) {
    return {
        name: name,
        children: [],
    }
}


/**
 *
 * @param {NodeObj} obj
 * @returns {Node}
 */
export function nodeObjToNode(obj) {
    /**
     * @type {Node}
     */
    const rootNode = new Node(obj.name)

    if (obj.weight) {
        rootNode.setWeight(obj.weight)
    }

    if (obj.color) {
        rootNode.color = obj.color
    }

    if (obj.children) {
        for (const child of obj.children) {
            rootNode.addChild(nodeObjToNode(child))
        }
    }

    return rootNode
}


/**
 *
 * @param {Node} node
 * @returns {NodeObj}
 */
export function nodeToNodeObj(node) {
    /**
     * @type {NodeObj}
     */
    const obj = {
        name: node.name,
        weight: node.computeWeight(),
        color: node.color,
        children: [],
    }

    for (const child of node.children) {
        obj.children.push(nodeToNodeObj(child))
    }

    return obj
}


/* =============================== */

/**
 *
 * @param {string} name
 * @returns {BowlObj}
 */
export function createBowlObj(name) {
    return {
        name: name,
        root: null,
    }
}



/**
 *
 * @param {BowlObj} obj
 * @returns {Bowl}
 */
export function bowlObjToBowl(obj) {
    const bowl = new Bowl(obj.name, obj.root)

    if (obj.root) {
        const tree = nodeObjToNode(obj.root)
        bowl.nextColorIndex = tree.subtreeSize()
    }

    return bowl
}


/**
 *
 * @param {Bowl} bowl
 * @returns {BowlObj}
 */
export function bowlToBowlObj(bowl) {
    /**
     * @type {BowlObj}
     */
    const obj = {
        name: bowl.name,
        root: bowl.root,
    }

    return obj
}



/* =============================== */

/**
 *
 * @returns {BreadCalcObj}
 */
export function createBreadCalcObj() {
    return {
        bowls: [],
        currentBowlIndex: -1,
    }
}



/* =============================== */


/**
 *
 * @param {?NodeObj} obj - The empty root
 * @returns {NodeObj}
 */
export function createDefaultBread(obj, reset=true) {
    if (!obj) {
        obj = createNodeObj()
    }

    const node = nodeObjToNode(obj)

    if (reset) {
        node.children = []
    }

    const ingredients = [
        ["flour", 500],
        ["water", 300],
        ["yeast", 5],
        ["salt", 10],
    ]

    node.addMultiple(ingredients)

    return nodeToNodeObj(node)
}


/**
 *
 * @param {NodeObj} obj
 * @param {string} path
 * @param {number} percentage
 * @returns {NodeObj}
 */
export function setBakerPercentage(obj, path, percentage) {
    const tree = nodeObjToNode(obj)

    const treePath = new TreePath(path)

    const node = treePath.find(tree)

    if (node) {
        node.setPercent(percentage)
    }

    return nodeToNodeObj(tree)
}