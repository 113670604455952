<script setup>
import { defineProps, defineEmits } from "vue";

import HorizontalList from "../components/HorizontalList.vue";
import HorizontalListItem from "../components/HorizontalListItem.vue";

const props = defineProps(["ingredients", "currentIngredientIndex"]);

defineEmits(["select", "addIngredient"]);

function shortenedName(name) {
  return name.slice(0, 5)
}

function itemStyle(item) {
  return {
    "background-color": item.color ?? "gray",
  };
}
</script>

<template>
  <horizontal-list>
    <horizontal-list-item
      v-for="item in props.ingredients"
      :key="item.name"
      @click="$emit('select', item)"
      class="item"
      :style="itemStyle(item)"
      :title="item.prettyName"
      >{{ shortenedName(item.prettyName) }}</horizontal-list-item
    >
    <horizontal-list-item
      @click="$emit('addIngredient')"
      class="item item-add"
      >+</horizontal-list-item
    >
  </horizontal-list>
</template>

<style scoped>

.item {
  box-sizing: border-box;
  color: white;
  border: none;
  border-radius: 9%;
  width: 3.3em;
  height: 3.3em;

  margin: 0 0.5em;
}

.item:first-child {
  margin-left: 1em;
}

.item-add {
  background-color: gray;
}
</style>
