<script setup>
import { defineProps, defineEmits, computed, ref, watch } from "vue";

import IngredientList from "../components/IngredientList.vue";
import IngredientVisualization from "../components/IngredientVisualization.vue";
import IngredientControls from "../components/IngredientControls.vue";
import RecipeTable from "../components/RecipeTable.vue";

import { Node } from "../lib/breadcalc"
import { nodeObjToNode } from "@/lib/breadcalc/functions";

/**
 * @property {BowlObj} bowl
 */
const props = defineProps(["bowl"]);
const emit = defineEmits(["update"])

const showAddIngredientDialog = ref(false)
const highlightedIngredient = ref(null)
const newIngredient = ref(null)


watch(() => props.bowl, () => {
  deselect()
})


const bread = computed(() => {
  if (!props.bowl || !props.bowl.root) {
    return null
  }

  return nodeObjToNode(props.bowl.root)
})

const ingredients = computed(() => {
  if (!bread.value) {
    return []
  }

  return [...bread.value.children]
})


function select(ing) {
  highlightedIngredient.value = ing
}

function deselect() {
  highlightedIngredient.value = null
}


function onIngredientUpdate({ingredient, mode, value}) {
  if (value <= 0) {
    ingredient.parent.deleteChild(ingredient)
    deselect()
    return
  }

  if (mode === "weight") {
    ingredient.setWeight(value)
  } else if (mode === "bakerPercentage") {
    ingredient.setPercent(value)
  }
}

function startAddIngredient() {
  newIngredient.value = new Node("")
  showAddIngredientDialog.value = true
}

function confirmAddIngredient() {
  bread.value.addChild(newIngredient.value)
  newIngredient.value = null
  showAddIngredientDialog.value = false
  emit("update")
}

</script>

<template>
  <div class="vis-and-legend">
    <ingredient-list :ingredients="ingredients" @select="select" @addIngredient="startAddIngredient"></ingredient-list>

    <ingredient-visualization
      :ingredients="ingredients"
      :highlightedIngredient="highlightedIngredient"
      @select="select"
    ></ingredient-visualization>
  </div>
  <div class="controls">
    <template v-if="highlightedIngredient">
      <ingredient-controls :ingredient="highlightedIngredient" @update:confirm="onIngredientUpdate"></ingredient-controls>
    </template>
  </div>

  <recipe-table v-if="bread" :bread="bread"></recipe-table>

  <q-dialog v-model="showAddIngredientDialog" persistent>
    <q-card>
      <q-card-section>
        <q-input v-model="newIngredient.name" filled />

        <ingredient-controls :ingredient="newIngredient" @update:modelValue="onIngredientUpdate" :showTitle="false" :showUpdate="false"></ingredient-controls>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="Cancel" v-close-popup />
        <q-btn flat label="Add" color="primary" @click="confirmAddIngredient()" :disable="!newIngredient.name || newIngredient.weight" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<style scoped>
.vis-and-ctrl {
  display: flex;
  flex-direction: column;
}
</style>