<script setup>
</script>

<template>
  <div class="item clickable no-select">
    <slot></slot>
  </div>
</template>


<style scoped>
.item {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}

.item.clickable {
  cursor: pointer;
}

.item.no-select {
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.item:focus {
  outline: none;
}
</style>
