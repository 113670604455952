<script setup>
import { defineProps, defineEmits, ref, watch, computed, onMounted } from "vue";

import { Node } from "@/lib/breadcalc";

const props = defineProps({
  ingredient: {
    type: Node,
    required: true,
  },
  showTitle: {
    type: Boolean,
    default: true,
  },
  showUpdate: {
    type: Boolean,
    default: true,
  }
});
const emit = defineEmits(["update:modelValue", "update:confirm"]);


const mode = ref("weight")
const value = ref(0)



function setValueFromIngredient(ingredient, mode) {
  if (!ingredient) {
    return
  }

  if (mode === "weight") {
    value.value = ingredient.weight
  } else if (mode === "bakerPercentage") {
    value.value = ingredient.percent * 100
  }
}


watch(mode, (curr) => {
  setValueFromIngredient(props.ingredient, curr)
})

watch(value, (curr) => {
  if (!curr) {
    value.value = 0
  }

  emitUpdate("update:modelValue")
})

watch(() => props.ingredient, (curr) => {
  setValueFromIngredient(curr, mode.value)
})


onMounted(() => {
  setValueFromIngredient(props.ingredient, mode.value)
})


const bounds = computed(() => {
  switch(mode.value) {
    case "weight": return {
      min: 0,
      max: 2000,
    }
    case "bakerPercentage": return {
      min: 0,
      max: 100,
    }
    default: return {
      min: 0,
      max: 0,
    }
  }
})


function emitUpdate(event) {
  const _mode = mode.value
  const _value = _mode === "bakerPercentage" ? value.value / 100 : value.value

  emit(event, {
    ingredient: props.ingredient,
    mode: _mode,
    value: _value,
  })
}

</script>


<template>
  <div class="text-h4" v-if="showTitle">
    {{ props.ingredient.prettyName }}
  </div>

  <q-btn-group outline>
    <q-btn outline :color="mode == 'weight' ? 'secondary' : null" label="Weight" icon="scale" @click="mode = 'weight'" />
    <q-btn outline :color="mode == 'bakerPercentage' ? 'secondary' : null" label="Baker Percentage" icon="percent" @click="mode = 'bakerPercentage'" />
  </q-btn-group>

  <div class="q-pa-md">
    <div>
      <q-slider v-model="value" :min="bounds.min" :max="bounds.max" />
    </div>
    <div>
      <q-input v-model.number="value" filled type="number" />
    </div>
  </div>

  <div v-if="showUpdate">
    <q-btn outline color="primary" label="Update" icon="save" @click="emitUpdate('update:confirm')" />
  </div>
</template>



<style scoped>

</style>